//代理商审核接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-03-8
 */

//代理商审核分页查询
export function agentApplyPageList(parameter){
    return axios({
        url: '/agentApply/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//代理商审核详情
export function agentApplyDetail(parameter){
    return axios({
        url: '/agentApply/detail',
        method: 'post',
        params: parameter 
    })
}


//代理商审核审核
export function agentApplyAudit(parameter){
    return axios({
        url: '/agentApply/apply',
        method: 'post',
        data: parameter
    })
}
